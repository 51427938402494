import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      title="404 - Not Found"
      description={data.site.siteMetadata.description}
    >
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
